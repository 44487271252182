<template>
  <div class="bread-crunmb">
     <span class="big-title" >{{title}}</span>
  </div>
</template>

<script>
export default {
    props:{
        title:{
            type:String,
            default: ''
        }
    }
}
</script>

<style lang="less">
.bread-crunmb {
    display: flex;
    font-size: 18Px;
     line-height: 36px; 
      color: #2a2a31;
      font-weight: bold;
      span {
        color: #2a2a31;
        padding-left: 6Px;
        display: inline-block;
        cursor: pointer;
      }
    .big-title {
        font-size: 18Px;
        font-family: SourceHanSansCN-Medium;
    }
  .big-title::after {
    content: "";
    width: 60px;
    height: 4px;
    background: #6e76ff;
    opacity: 1;
    border-radius: 5px;
    display: block;
    margin-top: 7px;
  }
}


</style>