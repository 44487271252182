<template>
  <div class="home-chart">
    <p class="home-chart-title">一周使用统计 <a class="total-bt" @click="linkToCharts">查看其他统计</a> </p>
    <div id="home-chart-content" ref="homeChart"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "home-chart",
  props: {
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  mounted() {},
  watch: {
    list: {
      handler(n, o) {
        let xAxisData = [];
        let data = [];
        n.forEach((element) => {
          xAxisData.push(element.day);
          let obj = { value: element.count, symbol: "none" };
          data.push(obj);
        });
        if (n.length > 0) {
          if (!this.chart) {
            this.chart = echarts.init(this.$refs.homeChart);
          }
          setTimeout(() => {
            this.initChartOption(xAxisData, data);
          }, 1200);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    initChartOption(xAxisData, data) {
      let option = {
        grid: {
          left: 30,
          bottom: 20,
          top: 30,
          right: 10,
        },
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          offset: 0,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            color: "#6E76FF",
          },
          type: "category",
          data: xAxisData,
        },
        yAxis: {
          type: "value",
          axisLabel: {
            color: "#6E76FF",
          },
          name: "(次数)",
          nameLocation: "end",
          nameTextStyle: {
            color: "#6E76FF",
            padding: [0, 0, 0, -30],
          },
          splitLine: {
            lineStyle: {
              type: "dashed",
            },
          },
        },
        series: [
          {
            data: data,
            type: "line",
            smooth: true,
            lineStyle: {
              shadowColor: "#6E76FF",
              shadowBlur: 10,
            },
          },
        ],
      };
      this.chart.setOption(option);
    },
    //跳转统计页面
    linkToCharts(){
      this.$router.push({ name: "chart" });
    }
  },
};
</script>

<style lang="less">
.home-chart {
    background: white;
    border-radius: 20Px;
    padding: 26Px;
    margin-top: 26Px;
    position: relative;
    overflow: hidden;
    .home-chart-title {
        font-size: 16Px;
        font-weight: bold;
    }
    .total-bt{
      color: #6e76ff;
      margin-left: 40px;
    }
    #home-chart-content {
        height: 200Px;
    }
}
</style>