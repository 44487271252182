<template>
  <div class="home-right-side">
    <div class="home-right-header">
      <a-avatar :size="56" icon="user" :src="model.headImg" />
      <div class="user-name-warp">
        <p class="name">{{ model.teacherName }}</p>
        <p class="school">{{ model.schoolName }}</p>
      </div>
    </div>
    <ul class="data-warp">
      <li class="data-item">
        <p class="title">学科</p>
        <p class="value">{{ model.teach }}</p>
      </li>
      <li class="data-item">
        <p class="title">浏览量</p>
        <p class="value">{{ model.total }}</p>
      </li>
      <li class="data-item">
        <p class="title">收藏</p>
        <p class="value">{{ model.favorite }}</p>
      </li>
      <li class="data-item">
        <p class="title">下载</p>
        <p class="value">{{ model.downCount }}</p>
      </li>
    </ul>
    <br />
    <div>
      <h3>足迹</h3>
      <ul class="foot-print-list">
        <li
          class="foot-print-list-item"
          v-for="(item,index) in model.footPointList"
          :key="index"
        >
          <div class="icon-warp">
            <a-icon type="pushpin" style="color: #6e76ff" theme="filled" />
          </div>
          <div class="item-right">
            <p class="action-name">你的足迹</p>
            <p class="action-time">{{item.time}}</p>
            <p class="action-detail">{{ item.what2Do }}</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "home-right-side",
  props: {
    model: {
      type: Object,
      default: () => {
        return {
          teacherName: "",
          downCount: 0,
          favorite: 0,
          teach: "语文,数学",
          teacherId: 0,
          total: 0,
          footPointList: [],
          headImg:
            "https://ss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=3187564358,380870727&fm=26&gp=0.jpg",
          schoolName: "会员学校",
          statistics: []
        };
      },
    },
  },
};
</script>

<style lang="less">
.home-right-side {
    .home-right-header {
        display: flex;
        align-items: center;
        .user-name-warp {
            margin-left: 15Px;
            p {
                margin: 0;
            }
            .name {
                font-size: 17Px;
                font-weight: bold;
            }
            .school {
                font-size: 13Px;
                color: #8E8E93;
            }
        }
    }
    .data-warp {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-top: 26Px;
        .data-item {
            text-align: center;
            border-right: 1Px solid #D1D1D6;
            padding-right: 20Px;
            p {
                margin: 0;
            }
            .title {
                font-size: 13Px;
                color: rgba(0, 0, 0, 0.6);
            }
            .value {
                font-size: 14Px;
            }
        }
        .data-item:nth-last-child(1) {
            border: none;
            .value {
                color: @primary-color;
            }
        }
    }
    .foot-print-list {
        padding: 0;
        margin-top: 15Px;
        .foot-print-list-item {
            background: #EDF0F5;
            border-radius: 10Px;
            padding: 15Px;
            display: flex;
            margin-bottom: 20Px;
            .icon-warp {
                padding-top: 1Px;
            }
            .item-right {
                margin-left: 15Px;
            }
            p {
                margin: 0;
            }
            .action-name {
                font-size: 14Px;
                font-weight: bold;
            }
            .action-time {
                font-size: 13Px;
                padding: 4Px 0;
            }
            .action-detail {
                font-size: 14Px;
            }
        }
    }
}
</style>