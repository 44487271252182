<template>
  <div class="home" ref="home">
    <div class="home-left" :style="{ width: homeLeftWidth }">
      <bread-crumb title="首页"></bread-crumb>
      <div class="clock-warp">
        <clock></clock>
      </div>
      <home-intro></home-intro>
      <home-chart :list="infoData.statistics"></home-chart>
      <br />
      <h3 style="margin:0;">最近使用</h3>
      <ul class="courses">
        <course-item
          class="course-item"
          v-for="(item, index) in infoData.hallTop2"
          :key="index"
          :index="index"
          :item="item"
          @navigateToDetail="navigateToDetail(item)"
        >
        </course-item>
      </ul>
    </div>
    <div class="home-right">
      <div class="right-radius"></div>
      <div class="right-info">
        <home-right-side :model="infoData"></home-right-side>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "../../components/BreadCrumb.vue";
import Clock from "../../components/Clock.vue";
import HomeChart from "../../components/HomeChart.vue";
import HomeIntro from "../../components/HomeIntro.vue";
import HomeRightSide from "../../components/HomeRightSide.vue";
import { getMyIndexData } from "@/api/home";
import CourseItem from "../../components/CourseItem.vue";
import { getIconWithType } from "@/utils/util";
export default {
  components: {
    BreadCrumb,
    Clock,
    HomeRightSide,
    HomeIntro,
    HomeChart,
    CourseItem,
  },
  data() {
    return {
      homeLeftWidth: "1000px",
      infoData: {
        downCount: 0,
        favorite: 0,
        teach: "语文,数学",
        teacherId: 0,
        total: 0,
        footPointList: [],
        headImg:
          "https://ss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=3187564358,380870727&fm=26&gp=0.jpg",
        schoolName: "会员学校",
        statistics: [],
        hallTop2: [],
      },
    };
  },
  mounted() {
    this.resetWidth();
    window.onresize = () => {
      this.resetWidth();
    };
    this.requestInfo();
  },
  methods: {
    requestInfo() {
      getMyIndexData().then((res) => {
        console.log(res);
        if (res.code == 200) {
          res.data.myIndexData.hallTop2.forEach((element) => {
            element.icon = getIconWithType(element.Type);
          });
        }
        this.infoData = res.data.myIndexData;
      });
    },
    resetWidth() {
      if(this.$refs.home) {
        let width = window
        .getComputedStyle(this.$refs.home)
        .width.replace("px", "");
        this.homeLeftWidth = `${width - 400}px`;
      }
    },
    navigateToDetail(item) {
      this.$router.push({ name: "courseDetail", query: { course: item.Type } });
    },
  },
};
</script>

<style lang="less">
.home {
  position: relative;
  .home-left {
    position: relative;
    .clock-warp {
      position: absolute;
      right: 0px;
      top: 0;
    }
    .courses {
        padding: 20px 0;
        display: flex;
        flex-wrap: wrap;
    }
  }
  .home-right {
    position: fixed;
    top: 0;
    right: 0;
    background: white;
    width: 400Px;
    bottom: 0;
    display: flex;
    .right-radius {
      width: 40Px;
      border-top-right-radius: 40Px;
      border-bottom-right-radius: 40Px;
      background: #EDF0F5;
      min-height: 800Px;
    }
    .right-info {
      width: 360Px;
      padding: 40Px;
      min-height: 800Px;
      overflow-y: scroll;
      -ms-overflow-style: none;  //IE 10+
      overflow: -moz-scrollbars-none;  //Firefox
    }
    .right-info::-webkit-scrollbar {
      width: 0;
    }
  }
}

</style>