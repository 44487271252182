<template>
  <div class="home-intro">
      <p class="home-intro-title">中午好！欢迎来到巴蜀小学课程博览馆</p>
      <p class="home-intro-desc">
          云巴蜀课程博览馆是由巴蜀小学核心团队研发，旨在打造学校精品课程资源库，帮助老师备课、上课。课程既有单元解读、又有单课目标分解，方便老师明晰教学脉络，把控教学目标，创生教学内容，是一个能很好地实现资源共享，师生共创的教学助手。
      </p>
      <div class="home-intro-icon">
          <img src="@/assets/expo1.png" alt="" srcset="">
      </div>
  </div>
</template>

<script>
export default {
    name: 'home-intro'
}
</script>

<style lang="less">
.home-intro {
    background: white;
    border-radius: 20Px;
    padding: 26Px;
    margin-top: 26Px;
    position: relative;
    overflow: hidden;
    .home-intro-title {
        font-size: 16Px;
        font-weight: bold;
    }
    .home-intro-desc {
        width: 70%;
        margin: 0;
        color: #2A2A31;
    }
    .home-intro-icon {
        position: absolute;
        width: 160Px;
        height: 160Px;
        background: rgba(110, 118, 255, .2);
        right: 20Px;
        bottom: -60Px;
        border-radius: 80Px;
        img {
            width: 160Px;
            height: 160Px;
            position: relative;
            top: -30Px;
        }
    }
}
</style>